/* Ionic Variables and Theming. */
/* This is just a placeholder file For more info, please see: */
/* https://ionicframework.com/docs/theming/basics */

/* To quickly generate your own theme, check out the color generator */
/* https://ionicframework.com/docs/theming/color-generator */

:root {
  --ion-color-primary: #6b382c;
  --ion-color-primary-rgb: 107,56,44;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #5e3127;
  --ion-color-primary-tint: #7a4c41;

  --ion-color-secondary: #de9c8e;
  --ion-color-secondary-rgb: 222,156,142;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #c3897d;
  --ion-color-secondary-tint: #e1a699;

  --ion-color-tertiary: #997f3f;
  --ion-color-tertiary-rgb: 153,127,63;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #877037;
  --ion-color-tertiary-tint: #a38c52;

  --ion-color-success: #3c8a29;
  --ion-color-success-rgb: 60,138,41;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #357924;
  --ion-color-success-tint: #50963e;

  --ion-color-warning: #997f3f;
  --ion-color-warning-rgb: 153,127,63;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255,255,255;
  --ion-color-warning-shade: #877037;
  --ion-color-warning-tint: #a38c52;

  --ion-color-danger: #de9c8e;
  --ion-color-danger-rgb: 222,156,142;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 0,0,0;
  --ion-color-danger-shade: #c3897d;
  --ion-color-danger-tint: #e1a699;

  --ion-color-medium: #2b2b2b;
  --ion-color-medium-rgb: 43,43,43;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #262626;
  --ion-color-medium-tint: #404040;

  --ion-color-light: #e8d8ce;
  --ion-color-light-rgb: 232,216,206;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #ccbeb5;
  --ion-color-light-tint: #eadcd3;

  --ion-background-color: rgb(250, 252, 251);

}
