/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.backTransparent {
  --background: transparent !important;
}

@media print {
  .ion-page {
    display: initial !important;
    position: initial !important;
  }
  body {
    position: initial !important;
    max-height: initial !important;
    overflow: auto !important;
  }
  ion-router-outlet {
    overflow: auto !important;
    contain: none !important;
    position: initial !important;
  }
  ion-content {
    display: unset !important;
    position: fixed !important;
    --offset-bottom: unset !important;
    top: 170px;
    .print-pdf-total {
      display: block !important;
      font-size: x-large;
    }
  }
  ion-footer {
    display: none !important;
  }

}

.ai-agent-chat-avatar-container {
  bottom: 50px !important;
}

